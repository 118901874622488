// WRITE ARTICLE
export const dealBuilder = {
  order: 4,
  name: "Deal Hub",
  subcategory: "Navigating Tower Hunt",
  markdown: `# Deal Hub

  - Research historical {% inlineRouterLink articleId="the-role-of-investments" %}investments{% /inlineRouterLink %}
  - View "before" and "after" {% inlineRouterLink articleId="understand-the-capital-stack" %}capital stacks{% /inlineRouterLink %} for specific {% inlineRouterLink articleId="the-role-of-investments" %}investments{% /inlineRouterLink %}
  - Add {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %} to existing {% inlineRouterLink articleId="the-role-of-investments" %}investments{% /inlineRouterLink %}
  - {% inlineRouterLink articleId="create-an-investment" %}Create investments{% /inlineRouterLink %} that modify existing capital stacks
  - {% inlineRouterLink articleId="create-a-portfolio-investment" %}Create portfolio investments{% /inlineRouterLink %}`,
};
